@import '@/src/styles/mixins';
.linkItem {
  .innerLink {
    /* box-shadow: 0px 8px 32px rgba(42, 59, 66, 0.08); */
    /* @include smoothMixin(0.4s); */
    /* // .text{ */
    /* //   &:after{ */
    /* //     content:'\279D'; */
    /* //     font-size: 22px; */
    /* //     color:#5d5bea; */
    /* //   } */
    /* // } */
    &.disabled {
      pointer-events: none;
      /* color: #848a97; */
      /* img { */
      /* filter: grayscale(50%); */
      /* opacity: 90; */
      /* } */
      /* .text { */
      /* &:after { */
      /* content: ''; */
      /* } */
      /* } */
    }
  }
}
@media all and (min-width: 1120px) {
  .linkItem {
    .innerLink {
      &:hover {
        /* box-shadow: 10px 8px 30px rgba(42, 59, 66, 0.2); */
      }
    }
  }
}
